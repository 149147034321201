<template>
  <footer v-if="!excludeFooter" class="footer mx-2 px-6">
    <div class="row">
      <div class="col-lg-12">
        <Logo size="small" color="blue" />
        <p class="copyright mb-2 text-center text-[0.65rem] text-legacy-muted">
          Use of this platform is subject to our
          <a
            class="font-bold text-legacy-muted"
            target="_blank"
            rel="noopener"
            href="https://removify.com/terms-of-business/#client-portal"
          >
            Client Portal Terms of Use <i class="ri ri-open"></i>
          </a>,
          <router-link
            class="font-bold text-legacy-muted"
            :to="{
              name: 'faq-article',
              params: {
                slug: 'general-faq-privacy-policy-and-cookie-policy',
              },
            }"
          >
            Privacy Policy
          </router-link>
          and
          <router-link
            class="font-bold text-legacy-muted"
            :to="{
              name: 'faq-article',
              params: {
                slug: 'general-faq-legal-disclaimer-regarding-use-of-this-platform',
              },
            }"
          >
            Legal Disclaimer
          </router-link>. <br />
          Engagement of our services is subject to our
          <a
            href="https://removify.com.au/terms-of-business/"
            target="_blank"
            rel="noopener"
            class="font-bold text-legacy-muted"
          >Terms of Business <i class="ri ri-open"></i></a>.
        </p>
        <Version class="text-center" />
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  components: {
    Logo: defineAsyncComponent(
      () => import('@/components/Removify/BaseLogo.vue'),
    ),
    Version: defineAsyncComponent(
      () => import('@/components/Removify/Version.vue'),
    ),
  },
  computed: {
    pageName() {
      return this.$route.name;
    },
    excludeFooter() {
      return this.$route.meta && this.$route.meta.excludeFooter;
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  justify-content: center;

  :deep(.logo) {
    width: 22px;
    height: 22px;
    margin: 0 auto 1.5rem;

    img {
      width: 22px;
      height: 22px;
    }
  }
}

@media print {
  .footer {
    display: none;
  }
}
</style>
